import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MonthlyDataService {

  public allApi = '/api';
  public masterApi = '/v1/master';
  zoneid: any;
  private url = environment.keycloakConfig.url;
 
   

  private getMonthlyList = environment.monthlyServiceApi  + "/searchDetails";
  private viewMonthlyList = environment.monthlyServiceApi  + "/viewMonthlyData";
  private getExcel = environment.monthlyServiceApi  + "/downloadExcel/export";
  private getMonthlyDataView = environment.monthlyServiceApi + "/monthlyCol";
  private getMonthlyDataHeader = environment.monthlyServiceApi +  "/getTabs";
  private createMonthDataUrl = environment.monthlyServiceApi +  "/createMonthData";
  private approveMonthData = environment.monthlyServiceApi + "/approveMonthData";
  private updateMonthDataUrl = environment.monthlyServiceApi +  "/updateMonthData";
  private uploadExcelUrl = environment.defaultApi +  "/siwpstorageconnector/api/v1/storageconnect/fileUpload";
  private assessmentParamUrl = environment.defaultApi +  "/siwpassessment/api/v1/assess/getProfiles";
  private SaveAssessmentParamUrl = environment.defaultApi +  "/siwpassessment/api/v1/assess/updateProfiles";
  private uploadExcelErrorUrl = environment.defaultApi + '/siwpstorageconnector/api/v1/storageconnect/getData';
  // private getMasterColat = this.allApi + this.masterApi + '/getMasterCol';
  // private postMaster = this.allApi + this.masterApi + '/createInvestor';
  private uploadMultiDataExcelUrl = environment.defaultApi +  "/siwpstorageconnector/api/v1/storageconnect/multipleDataUpload";

  constructor(private http:HttpClient){}
  httpOptions = {
      headers: new HttpHeaders({
          'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS, PUT',
          'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type':  'application/json; charset = utf-8;',
          'Access-Control-Allow-Origin':'https://siwp-api-dev.olamdigital.com'
      })
    };
  
  

  getMonthlyData(name,year,month,status){
    let api_Url: string = `${this.getMonthlyList}?investorCompany=${name}&batchYear=${year}&batchMonth=${month}&status=${status}`
    return this.http.get(api_Url, this.httpOptions)
  }

  getExcelData(){
    let api_Url: string = `${this.getExcel}`
    return this.http.get(api_Url, this.httpOptions)
  }

  getMonthlyDataforView(){
    let api_Url: string = `${this.getMonthlyDataView}`
    return this.http.get(api_Url, this.httpOptions)
  }

  getMonthlyHeaders(){
    let api_Url: string = `${this.getMonthlyDataHeader}`
    return this.http.get(api_Url, this.httpOptions)
  }

  createMonthlyData(data){
    let api_url = `${this.createMonthDataUrl}`;
    return this.http.post(api_url,data);
  }

  viewMonthlyData(batchId,investorId,transMonth,transYear,userRole){
    let api_Url: string = `${this.viewMonthlyList}?batchId=${batchId}&investorId=${investorId}&transMonth=${transMonth}&transYear=${transYear}&userRole=${userRole}`
    return this.http.get(api_Url, this.httpOptions)
  }

  approveMonthlyData(body){
    console.log(body)
    let api_Url: string = `${this.approveMonthData}`
    return this.http.put(api_Url, body,{observe:'response'});
  }

  updateMonthlyData(data,role){
    let api_url = `${this.updateMonthDataUrl}?userRole=${role}`;
    return this.http.put(api_url,data);
  }
  uploadExcel(data){
    let params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
    };
    // let api_url = `${this.uploadExcelUrl}?userId=${body?.userId}&investorId=${body?.investorId}&uploadMonth=${body?.uploadMonth}&uploadYear=${body?.uploadYear}`;
    let api_url = `${this.uploadExcelUrl}`;
    return this.http.post(api_url,data,options);
  }

  getAssessmentList(){
    let api_Url = `${this.assessmentParamUrl}`;
    return this.http.get(api_Url);
  }

  saveAssessmentParamList(data){
    let api_Url = `${this.SaveAssessmentParamUrl}`;
    return this.http.put(api_Url,data);
  }
  getUploadExcelError(userName) {
    let api_Url: string = `${this.uploadExcelErrorUrl}?createdBy=${userName}`;   
    return this.http.get(api_Url);
}
uploadMultipleDatExcel(data){
  let params = new HttpParams();
  const options = {
    params: params,
    reportProgress: true,
  };
  let api_url = `${this.uploadMultiDataExcelUrl}`;
  return this.http.post(api_url,data,options);
}
}

